import { getData, getDataBydId, getPaginatedData } from './commonService';

export default class MenuService {


    #defaultUrl = "/api/Menu";
    #defaultResourceName = "Sayfa";

    async getAllMenu() {
        return await getPaginatedData(this.#defaultUrl, this.#defaultResourceName);
    }

    async getMenuTree() {
        return await getData(this.#defaultUrl + "/menuTree", this.#defaultResourceName);
    }

    async getMenuById(menuId) {
        return await getDataBydId(this.#defaultUrl, menuId, this.#defaultResourceName);
    }
}