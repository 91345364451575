<template>
    <AdminToast/>
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <ManagerUserLeftPanel :data-item='dataItem' />
                </div>

                <span>
					<hr>
				</span>

                <span>

                <Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger p-component mr-2 pl-3 pr-3 pt-2 pb-2' />

                <Sidebar v-model:visible="visibleLeft" :baseZIndex="1000">
					<h3>Sayfalar</h3>

					<p class="text-lg">
						<b>Görebileceği Sayfalar:</b> Kullanıcının yetkisiyle orantılı olarak görebileceği sayfaları işaretleyin. Kullanıcı Admin değil ise tümü veya gerekli olmayan sayfalar seçilmemelidir.<br>
						<br>
                        <span></span>
                        <span class="text-red-500 font-bold ml-2 mr-2">*</span> işaretli alanlar zorunlu alanlardır.<br>	
					</p>

				</Sidebar>

				</span>

            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <ManagerUserTabMenu :manager-user-id='managerUserId'></ManagerUserTabMenu>

                <div class='grid'>

                    <div class='col-12'>

                        <div class='field grid mb-5'>
                            <label for='menuList' class='col-12 mb-2 md:col-2 md:mb-0'>Görebileceği Sayfalar</label>
                            <div class='col-12 md:col-10'>
                                <TreeTable id='menuList' :value="menuList" selectionMode="checkbox" v-model:selectionKeys="selectedMenus">
                                    <Column field="title" header="Sayfa İsmi" :expander="true"></Column>
                                </TreeTable>
                            </div>
                        </div>

                        <div class='field grid'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                            <div class='col-12 md:col-10'>
                                <Button id='saveButton' label='Kaydet' icon='pi pi-save' class='mr-2 mb-2' @click='save'></Button>
                            </div>
                        </div>

                    </div>

                </div>

            </div>

        </div>

    </div>
</template>

<script>

import ManagerUserService from '@/services/managerUserService';
import { showErrorMessage } from '@/components/shared/toast/toastFunction';
import { showSuccessMessage } from '@/components/shared/toast/toastFunction';
import AdminToast from "@/components/shared/toast/adminToast";
import ManagerUserLeftPanel from './managerUserLeftPanel';
import ManagerUserTabMenu from './managerUserTabMenu';
import ManagerUserPageService from '../../services/managerUserPageService';
import MenuService from '../../services/menuService';
import { checkUserRole } from '../common/commonConstantFunctions';
import { getManagerUser } from '../common/commonFunctions';

export default {
    _managerUserService: null,
    _menuService: null,
    _managerUserMenuService:null,
    components: { ManagerUserTabMenu, AdminToast, ManagerUserLeftPanel},
    created() {
        this._managerUserService = new ManagerUserService();
        this._managerUserMenuService = new ManagerUserPageService();
        this._menuService = new MenuService();
        this.managerUserId = this.$route.params.managerUserId;

    },
    async mounted() {
        checkUserRole(this,'Admin');
        this.dataItem = await getManagerUser(this.managerUserId);
        this.selectedMenus = JSON.parse(this.dataItem.menuState);
        await this.fillMenu();
        await this.fillManagerUserProject(this.managerUserId);
    },
    data() {
        return {
            visibleLeft: false,
            managerUserId: 0,
            menuList:[],
            selectedMenus:[],
            dataItem: {
                profilePicture: ''
            },
        };
    },

    methods: {
        async save() {
            let selectedMenuList = await this.buildSelectedPageList();
            await this.setManagerUserMenu(selectedMenuList);
        },

        async setManagerUserMenu(selectedMenuList) {
            await this._managerUserMenuService.deleteManagerUserMenu(this.managerUserId);
            for(const menu of selectedMenuList) {
                await this._managerUserMenuService.insertManagerUserMenu(this.managerUserId, menu);
            }

            let updateResponse =await this._managerUserService.updateManagerUserPages(this.managerUserId, this.selectedMenus);
            if (updateResponse.isSuccess) {
                showSuccessMessage(this, 'Yönetici Yetki Tanımlamaları Güncelleme başarılı');
            } else {
                showErrorMessage(this, 'Yönetici Yetki Tanımlamaları Güncellemede Sorun İle Karşılaşıldı');
            }
        },


        async buildSelectedPageList() {
            let planinMenuResponse = await this._menuService.getAllMenu();
            let selected = [];
            planinMenuResponse.data.forEach((value) => {
                if (this.selectedMenus[value.id] != null) {
                    selected.push(value.id);
                }
            });
            return selected;
        },

        async fillMenu() {
            let menuResponse = await this._menuService.getMenuTree();
            if (menuResponse.isSuccess) {
                this.menuList = menuResponse.data.root;
            }
        },

        async fillManagerUserProject(managerUserId) {
            let getResponse = await this._managerUserMenuService.getAllMenus(managerUserId);
            if(getResponse.isSuccess)
            {
                this.selectedProjects = getResponse.data;
            }
        },
    },

};

</script>


<style scoped>

</style>
