import { deleteDataById, getListData, insertData } from './commonService';

export default class ManagerUserPageService {

    #defaultUrl = "/api/ManagerUser/Menu";
    #defaultResourceName = "Sayfa";

    async insertManagerUserMenu(managerUserId, menuId) {
        const createData = {
            "menuId": menuId,
            "managerUserId": managerUserId,
            "allowWrite": true
        }
        return await insertData(this.#defaultUrl, createData, this.#defaultResourceName);
    }

    async deleteManagerUserMenu(managerUserId) {
        return await deleteDataById(this.#defaultUrl, managerUserId, this.#defaultResourceName);
    }

    async getAllMenus(managerUserId) {
        return await getListData(this.#defaultUrl + "/" + managerUserId + "/IdList", this.#defaultResourceName);
    }
}